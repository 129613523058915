import React, { useState } from "react";
import { Box, Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import {
    AccountAssessmentDefinitionsScoresDetail,
    ResultChartType
} from "../../../Assessments/type";
import { CustomAccountBranding } from "../../../Branding/type";
import BarChart from "../../../Assessments/AssessmentDetails/components/Analysis/BarChart";
import GaugeChart from "../../../Evaluations/EvaluationAnalysis/EvaluationAnalysisContainer/GaugeChart";

interface AssessmentCategoryScoreTabsCardProps {
    assessmentDefinitions: AccountAssessmentDefinitionsScoresDetail[];
    branding: CustomAccountBranding;
}

const AssessmentCategoryScoreTabsCard: React.FC<AssessmentCategoryScoreTabsCardProps> = ({
    assessmentDefinitions, branding,
}) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const currentAssessmentDefinition = assessmentDefinitions[selectedTab];

    return (
        <Card sx={{ padding: 2, boxShadow: 3, borderRadius: 2, fontFamily: 'Inter' }}>
            <Grid container>
                {/* Tabs at the top of the card */}
                <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item md={10}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            allowScrollButtonsMobile
                            sx={{
                                mb: 2,
                                "& .MuiTabs-flexContainer": {
                                    justifyContent: "start",
                                },
                                "& .MuiTab-root": {
                                    color: "#6B7280", // Default text color for tabs
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    textTransform: "none",
                                },
                                "& .Mui-selected": {
                                    color: "#197DAD !important", // Text color for the selected tab
                                    fontWeight: 700, // Optional: make the selected tab text bold
                                    backgroundColor: "#EFF3FF",
                                    borderRadius: "8px"
                                },
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "#197DAD", // Indicator color
                                },
                            }}
                        >
                            {assessmentDefinitions.map((definition, index) => (
                                <Tab
                                    key={definition.assessment_definition.id}
                                    label={definition.assessment_definition.name}
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item md={2} sx={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Box
                            component="img"
                            src={branding?.company_logo_primary}
                            alt=""
                            sx={{
                                width: '100%',
                                maxWidth: '100px',
                                height: 'auto',
                                maxHeight: '75px',
                                // margin: '20px 0',
                                objectFit: 'contain',
                            }}
                        />
                    </Grid>
                </Grid>

                {currentAssessmentDefinition.assessment_definition.result_chart_type === ResultChartType.BAR_CHART ? (
                    <>
                        <Grid item sx={{ display: 'flex', flexDirection: 'column', marginLeft: 4, marginBottom: '170px' }}>
                            {/* Card Header */}
                            <Typography sx={{ fontWeight: 700, fontSize: '24px', color: '#2E2E2E' }}>
                                Your Assessments Average vs. Industry
                            </Typography>
                            {/* Subtitle */}
                            <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#828282' }}>
                                Explore the average score across all your assessments compared to industry benchmarks.
                            </Typography>
                        </Grid>

                        <Grid item md={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1em', marginBottom: '70px' }}>
                            <div style={{
                                color: '#6A6A6A',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '27.233px',
                                letterSpacing: '-0.424px',
                            }}>
                                Total company average
                            </div>
                            <BarChart
                                cumulative_average_score={Number(currentAssessmentDefinition.assessment_definition_account_average_score)}
                                max_cumulative_score={Number(currentAssessmentDefinition.assessment_definition_max_possible_score)}
                                business_size_average_score={Number(currentAssessmentDefinition.assessment_definition_all_companies_average_score)}
                                bar_height='51px'
                                bar_width='650px'
                                bar_color='#053d58'
                                margin_left='15px'
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column', marginLeft: 4, marginBottom: '30px' }}>
                            {/* Card Header */}
                            <Typography sx={{ fontWeight: 700, fontSize: '24px', color: '#2E2E2E' }}>
                                Your Assessments Average vs. Industry
                            </Typography>
                            {/* Subtitle */}
                            <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#828282' }}>
                                Explore the average score across all your assessments compared to industry benchmarks.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    gap: 1
                                }}
                            >
                                <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#7F849B' }}>
                                    Your Resilience Score:
                                </Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#7F849B' }}>
                                    {currentAssessmentDefinition.assessment_definition_account_average_score}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                    gap: 1
                                }}
                            >
                                <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#7F849B' }}>
                                    Industry Average Resilience Score:
                                </Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#7F849B' }}>
                                    {currentAssessmentDefinition.assessment_definition_all_companies_average_score}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1em', marginBottom: '30px' }}>
                            <GaugeChart
                                score={currentAssessmentDefinition.assessment_definition_account_average_score}
                                maxScore={Number(currentAssessmentDefinition.assessment_definition_max_possible_score)}
                                minScore={Number(currentAssessmentDefinition.assessment_definition_min_possible_score)}
                                useCard={false}
                                redLegendText='At Risk'
                                yellowLegendText='Need Improvement'
                                greenLegendText='Optimal'
                                gaugeHeight={250}
                                gaugeWidth={400}
                                needleColor="#4763C7"
                                displayNeedleScore={true}
                                legendWidth='11px'
                                legendHeight='11px'
                                legendFontSize='15px'
                                legendFontWeight={600}
                                legendMaxWidth='100%'
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Card >
    );
};

export default AssessmentCategoryScoreTabsCard;
