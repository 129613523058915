/* eslint-disable indent */
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountConstants } from '../../../modules/Accounts/constants';
import useDeleteAccount from '../../../modules/Accounts/hooks/useDeleteAccount';
import { AccountDetails, Client } from '../../../modules/Accounts/types';
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
} from '../../EnhancedTable/EnhancedTableComponent';
import { Order } from './interfaces';
import { getComparator, stableSort } from './sortingUtils';
import useGetClients from '../../../modules/Accounts/hooks/useGetClients';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../redux/store.type';
import ConfirmationBox from '../../ConfirmationBox/ConfirmBox';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import useDebounce from '../../../hooks/useDebounce';
import { useBranding } from '../../../modules/Branding/reducers/getBranding';

export default function AccountListTable({ searchText }) {
  const accountListResponse = useSelector((state: RootStoreType) => {
    return state.allClientReducer.clientListReducer.accountListResponse;
  });

  const branding = useBranding();
  const evaluation_property_name_translation: string = branding?.evaluation_property_name_translation ?? 'Assessments'
  const { debounce } = useDebounce(1500);
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>('desc');
  const [rows, setRows] = React.useState<AccountDetails[] | null>(null);
  const [orderBy, setOrderBy] = React.useState<keyof Client>('last_updated_at');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [selectedStatus, setSelectedStatus] =
    React.useState<string>('All Clients');
  const [page, setPage] = React.useState(AccountConstants.default_page);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    AccountConstants.default_rows_per_page,
  );
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [selectedAccountId, setsSlectedAccountId] = useState('');
  const [isFirstRenderDone, setFirstRenderDone] = useState(false);

  useEffect(() => {
    if (accountListResponse) {
      setRows(accountListResponse.results);
    }
  }, [accountListResponse]);

  const deleteAccountReducer = useDeleteAccount();

  const clientListReducer = useGetClients();
  useEffect(() => {
    if (isFirstRenderDone) {
      debounce(() => {
        clientListReducer.clientList({ search: searchText });
        setSelectedPage(1);
      });
    }
  }, [searchText]);

  useEffect(() => {
    clientListReducer.clientList({});
    setFirstRenderDone(true);
  }, []);

  const handleStatus = (statusType: string) => {
    setSelectedStatus(statusType);
    clientListReducer.clientList({ status: statusType });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown> | SelectChangeEvent<string>,
    property: keyof Client,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: string[] = rows
        ? rows.map((n: { name: string }) => n.name)
        : [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + +page) * rowsPerPage - (rows ? rows.length : 0))
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort<Client>(rows || [], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * +rowsPerPage + +rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(event.currentTarget);
    setsSlectedAccountId(id);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(null);
    setsSlectedAccountId('');
  };

  const deleteAccount = (accountId: string) => {
    // deleteAccountById(accountId);
    setsSlectedAccountId(accountId);
    setOpenConfirmBox(true);
  };

  useEffect(() => {
    if (isFirstRenderDone) {
      clientListReducer.clientList();
    }
  }, [deleteAccountReducer.loading]);

  return !clientListReducer.loading &&
    !clientListReducer.error &&
    rows &&
    accountListResponse ? (
    <>
      {openConfirmBox && (
        <ConfirmationBox
          doOpen={openConfirmBox}
          deleteRecord={() => {
            deleteAccountReducer.deleteAccountById(selectedAccountId);
          }}
          doClose={() => {
            setOpenConfirmBox(false);
          }}
          confirmationMessage={"All the " + evaluation_property_name_translation + " related to this account will be deleted."}
        />
      )}
      <Box sx={{
        width: '100%', overflow: 'auto',
        border: '1px solid #E7E9F9',
      }}>
        <Paper sx={{ width: '100%', mb: 0 }}>
          <EnhancedTableToolbar
            handleStatus={handleStatus}
            pageCount={Math.ceil(
              accountListResponse.count /
              AccountConstants.default_rows_per_page,
            )}
            handlePage={(pageNumber) => {
              setSelectedPage(pageNumber);
              clientListReducer.clientList({ page: pageNumber, search: searchText });
            }}
            currentPage={selectedPage}
            currentStatus={selectedStatus}
            order={order}
            numSelected={selected.length}
          />
          <TableContainer
            sx={{
              maxHeight: '75vh',
              overflowY: 'auto',
            }}
          >
            <Table
              stickyHeader
              sx={{
                minWidth: 850,
                padding: '10px',
                '& .MuiTableHead-root': {
                  backgroundColor: '#fafafa',
                },
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy.toString()}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.length > 0 ? (
                  visibleRows.map((row) => {
                    const isItemSelected = isSelected(row.name.toString());
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          navigate(`/account-detail-view/${row.id}`);
                        }}
                        selected={isItemSelected}
                        sx={{
                          '& .MuiTableCell-root': {
                            borderBottom: 'none',
                            padding: '20px',
                          },
                        }}
                      >
                        <TableCell align="left">
                          <Typography
                            color="primary"
                            variant="subtitle1"
                            sx={{
                              color: '#464E5F',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: 'normal',
                              marginBottom: '4px',
                            }}
                          >
                            {row.name}
                          </Typography>
                          {/* <Typography
                            variant="subtitle1"
                            sx={{
                              color: '#B5B5C3',
                              fontSize: '13px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: 'normal',
                            }}
                          >
                            {row.name}
                          </Typography> */}
                        </TableCell>
                        <TableCell align="left">{row.website}</TableCell>
                        <TableCell align="left">
                          {row.city || <HorizontalRuleIcon />}
                        </TableCell>
                        <TableCell align={'left'}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: '#464E5F',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: 'normal',
                            }}
                          >
                            {
                              //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              row.last_evaluation_date ? (
                                moment(row.last_evaluation_date).format(
                                  'MMM DD, YYYY',
                                )
                              ) : (
                                <HorizontalRuleIcon
                                  sx={{ marginLeft: '20px' }}
                                />
                              )
                            }
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: '#B5B5C3',
                              fontSize: '13px',
                              fontWeight: 500,
                            }}
                          >
                            {
                              //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              row.last_evaluation_date &&
                              moment(row.last_evaluation_date).format(
                                'hh:mm A',
                              )
                            }
                          </Typography>
                        </TableCell>
                        <TableCell align={'left'}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: '#464E5F',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: 'normal',
                            }}
                          >
                            {row.last_updated_at ? (
                              moment(row.last_updated_at as string | Date).format('MMM DD, YYYY')
                            ) : (
                              <HorizontalRuleIcon sx={{ marginLeft: '20px' }} />
                            )}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: '#B5B5C3',
                              fontSize: '13px',
                              fontWeight: 500,
                            }}
                          >
                            {row.last_updated_at && 
                              moment(row.last_updated_at as string | Date).format('hh:mm A')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row.is_active ? (
                            <Chip
                              sx={{
                                borderRadius: '6px',
                                background: 'rgba(244, 255, 243, 1)',
                                color: '#000',
                                fontWeight: '600',
                                textAlign: 'center',
                              }}
                              label={'Active'}
                              color="success"
                            />
                          ) : (
                            <Chip
                              sx={{
                                borderRadius: '6px',
                                background: 'rgba(255, 226, 229, 1)',
                                color: '#000',
                                fontWeight: '600',
                                textAlign: 'center',
                              }}
                              label={'Inactive'}
                              color="success"
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>,
                            ) => handleClick(event, row.id.toString())}
                            aria-controls={open ? 'basic-menu' : undefined}
                          >
                            <MoreHorizRoundedIcon sx={{ fontSize: 40 }} />
                          </IconButton>
                          <Menu
                            sx={{
                              '& .MuiPaper-root': {
                                boxShadow:
                                  '0px 2px 3px 0px rgb(225 213 213 / 25%)',
                                border: '1px solid #E0E0E0',
                              },
                            }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate(
                                  `/account-detail-view/${selectedAccountId}`,
                                );
                              }}
                            >
                              View
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate(`/edit-account/${selectedAccountId}`);
                              }}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                deleteAccount(selectedAccountId.toString());
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6}>
                      <Typography
                        sx={{
                          color: '#A3A3A3',
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          textAlign: 'center',
                        }}
                      >
                        No records found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box >
    </>
  ) : (
    <CircularProgress sx={{ margin: '20vh 48%' }} />
  );
}
