import React, { useState } from "react";
import { Box, Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import {
    AccountAssessmentDefinitionsScoresDetail,
    AssessmentScoresDetail,
    ResultChartType
} from "../../../Assessments/type";
import { CustomAccountBranding } from "../../../Branding/type";
import BarChart from "../../../Assessments/AssessmentDetails/components/Analysis/BarChart";
import GaugeChart from "../../../Evaluations/EvaluationAnalysis/EvaluationAnalysisContainer/GaugeChart";

interface AssessmentScoreDetailsDropdownProps {
    assessmentScores: AssessmentScoresDetail;
    branding: CustomAccountBranding;
}

const AssessmentScoreDetailsDropdown: React.FC<AssessmentScoreDetailsDropdownProps> = ({
    assessmentScores, branding,
}) => {
    const pluralEvaluationPropertyName = branding.evaluation_property_name_translation ? branding.evaluation_property_name_translation : "Assessments";
    const singularEvaluationPropertyName = pluralEvaluationPropertyName.endsWith("s")
        ? pluralEvaluationPropertyName.slice(0, -1) // Remove the last character
        : pluralEvaluationPropertyName;
    const titleCaseSingularEvaluationPropertyName = singularEvaluationPropertyName
        ? singularEvaluationPropertyName[0].toUpperCase() + singularEvaluationPropertyName.slice(1).toLowerCase()
        : '';

    return (
        <Grid container sx={{ fontFamily: 'Inter' }}>
            {assessmentScores.assessment_definition.result_chart_type === ResultChartType.BAR_CHART ? (
                <>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column', marginLeft: 4, marginBottom: '90px' }}>
                        {/* Card Header */}
                        <Typography sx={{ fontWeight: 700, fontSize: '24px', color: '#2E2E2E' }}>
                            Single {titleCaseSingularEvaluationPropertyName} vs. Industry
                        </Typography>
                        {/* Subtitle */}
                        <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#828282' }}>
                            Compare the results of a single {String(singularEvaluationPropertyName).toLowerCase()} to the average of other companies in your industry.
                        </Typography>
                    </Grid>

                    <Grid item md={12}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '1em', marginBottom: '30px' }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.424px',
                        }}>
                            Single {titleCaseSingularEvaluationPropertyName} Result
                        </div>
                        <BarChart
                            cumulative_average_score={Number(assessmentScores.assessment_average_score)}
                            max_cumulative_score={Number(assessmentScores.assessment_definition_max_possible_score)}
                            business_size_average_score={Number(assessmentScores.assessment_definition_all_companies_average_score)}
                            bar_height='40px'
                            bar_width='500px'
                            bar_color='#2FA9E3'
                            arrow_color='#053d58'
                            margin_left='15px'
                            arrow_height='27px'
                            arrow_width='40px'
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column', marginLeft: 4, marginBottom: '30px' }}>
                        {/* Card Header */}
                        <Typography sx={{ fontWeight: 700, fontSize: '18px', color: '#2E2E2E' }}>
                            Single {titleCaseSingularEvaluationPropertyName} vs. Industry
                        </Typography>
                        {/* Subtitle */}
                        <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#828282' }}>
                            Compare the results of a single {String(singularEvaluationPropertyName).toLowerCase()} to the average of other companies in your industry.
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        md={4} sm={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 4,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                marginBottom: '8px',
                                gap: 1
                            }}
                        >
                            <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#7F849B' }}>
                                Your Resilience Score:
                            </Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#7F849B' }}>
                                {assessmentScores.assessment_average_score}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                marginBottom: '8px',
                                gap: 1
                            }}
                        >
                            <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#7F849B' }}>
                                Industry Average Resilience Score:
                            </Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#7F849B' }}>
                                {assessmentScores.assessment_definition_all_companies_average_score}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={7} sm={12}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start', marginBottom: '10px', }}>
                        <Box>
                            <GaugeChart
                                score={assessmentScores.assessment_average_score}
                                maxScore={Number(assessmentScores.assessment_definition_max_possible_score)}
                                minScore={Number(assessmentScores.assessment_definition_min_possible_score)}
                                useCard={false}
                                redLegendText='At Risk'
                                yellowLegendText='Need Improvement'
                                greenLegendText='Optimal'
                                gaugeHeight={200}
                                gaugeWidth={300}
                                needleColor="#4763C7"
                                gaugeBoxMargin="0px"
                                displayNeedleScore={true}
                                legendWidth='11px'
                                legendHeight='11px'
                                legendFontSize='15px'
                                legendFontWeight={600}
                                legendMaxWidth='100%'
                            />
                        </Box>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default AssessmentScoreDetailsDropdown;
