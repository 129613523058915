import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosError, AxiosResponse } from 'axios';

export interface ClientUserCreationReducer {
    status: RequestStatus;
    response: { success: string } | null;
}

const initialState: ClientUserCreationReducer = {
    status: RequestStatus.Init,
    response: null,
};

export interface ClientUserRequest {
    email: string;
    account_id: string;
    first_name: string;
    last_name?: string;
    profile_image?: string;
}

export const PostClientUser = createAsyncThunk(
    'auth/PostClientUser',
    async (clientUserPayload: ClientUserRequest, { rejectWithValue }) => {
        try {
            const response: AxiosResponse<string> = await axiosInstance.post(
                EndPoints.POST_GRANT_CLIENT_ACCESS,
                clientUserPayload
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const PostClientUserSlice = createSlice({
    name: 'auth/PostClientUserSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(PostClientUser.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(PostClientUser.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
            })
            .addCase(PostClientUser.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export default PostClientUserSlice.reducer;
