import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Paper,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    CircularProgress,
    Grid,
    Select,
    TextField
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BarGraphIcon from '../../../../assets/svg-components/BarGraphIcon';
import CheckmarkIcon from '../../../../assets/svg-components/CheckmarkIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkIcon from '@mui/icons-material/Link';
import { AccountAssessmentListDetail, AssessmentScoresDetail, AssessmentStatus } from '../../../Assessments/type';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Tenant } from '../../../../config/axios';
import useGetAssessmentScoreAnalysis from '../../../Assessments/hooks/useGetAssessmentScoreAnalysis';
import { resetAssessmentScoreAnalysisState } from '../../../Assessments/reducers/getAssessmentScoreAnalysis';
import { RootStoreType } from '../../../../redux/store.type';
import { useDispatch, useSelector } from 'react-redux';
import AssessmentScoreDetailsDropdown from './assessmentScoreDetailsDropdown';
import { CustomAccountBranding } from '../../../Branding/type';

// Define props for the component
interface CompletedSurveysProps {
    surveys: AccountAssessmentListDetail[];
    branding: CustomAccountBranding;
}

const CompletedSurveys: React.FC<CompletedSurveysProps> = ({ surveys, branding }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedSurvey, setSelectedSurvey] = useState<AccountAssessmentListDetail | null>(null);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [searchText, setSearchText] = useState('');
    const [statusType, setStatusType] = useState('');
    const [filteredAssessments, setFilteredAssessments] = useState<AccountAssessmentListDetail[]>(surveys);
    const isMenuOpen = Boolean(anchorEl);
    const pluralEvaluationPropertyName = branding.evaluation_property_name_translation ? branding.evaluation_property_name_translation : "Assessments";
    const singularEvaluationPropertyName = pluralEvaluationPropertyName.endsWith("s")
        ? pluralEvaluationPropertyName.slice(0, -1) // Remove the last character
        : pluralEvaluationPropertyName;
    const titleCasePluralEvaluationPropertyName = pluralEvaluationPropertyName
        ? pluralEvaluationPropertyName[0].toUpperCase() + pluralEvaluationPropertyName.slice(1).toLowerCase()
        : '';

    const { loading, error, getAssessmentScores } = useGetAssessmentScoreAnalysis();
    const location = useLocation();
    const dispatch = useDispatch();
    const baseUrl = window.location.href.replace(location.pathname, '/evaluate/');

    const assessmentScores = useSelector(
        (state: RootStoreType) =>
            state.assessmentReducers.getAssessmentScoreAnalysisReducer
                .assessmentScores as AssessmentScoresDetail,
    );

    const copyAssessmentLinkToClipboard = async () => {
        const link = baseUrl + (selectedSurvey?.uuid ?? '');
        await navigator.clipboard.writeText(link);
    };

    const backendReport = `api/v2/assessment/generate-report/?assessment_id=${selectedSurvey?.id as string}`;
    const reportGenerationUrl: string = (Tenant.domain as string) + backendReport;

    // Open the menu when the three dots are clicked
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, survey: AccountAssessmentListDetail) => {
        setAnchorEl(event.currentTarget);
        setSelectedSurvey(survey);
    };

    // Close the menu
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedSurvey(null);
    };

    const handleOpenReport = () => {
        const url = `${reportGenerationUrl}`;
        window.open(url, '_blank');
        handleMenuClose();
    };

    const handleCopyLink = () => {
        copyAssessmentLinkToClipboard();
        handleMenuClose();
    };

    const toggleRowExpansion = (index: number, assessmentUuid: string) => {
        dispatch(resetAssessmentScoreAnalysisState());
        setExpandedRow(expandedRow === index ? null : index);
        getAssessmentScores(assessmentUuid);
    };

    useEffect(() => {
        const filtered = surveys.filter((assessment) => {
            const matchesSearch =
                assessment.name.toLowerCase().includes(searchText.toLowerCase());

            const matchesStatus =
                statusType === '' ||
                (Array.isArray(statusType)
                    ? statusType.includes(assessment.readable_status)
                    : assessment.readable_status === statusType);

            return matchesSearch && matchesStatus;
        });
        setFilteredAssessments(filtered);
    }, [searchText, statusType, surveys]);

    return (
        <Grid container sx={{ padding: 2, backgroundColor: '#FFF', border: '1px solid #E7E9F9', borderRadius: '8px', fontFamilty: 'Inter' }}>
            {/* Header Section */}
            <Grid item sm={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', }}>
                        <BarGraphIcon />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '24px', color: '#233264' }}>
                            Completed {titleCasePluralEvaluationPropertyName}
                        </Typography>
                    </Box>
                    {/* <Button variant="text" sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 600, textDecoration: 'underline', color: "#233264", fontFamilty: 'Inter', marginRight: 2 }}>
                        View all
                    </Button> */}
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Typography variant="body2" sx={{ color: '#6A6A6A', marginBottom: 2, fontSize: '18px', fontWeight: 500, }}>
                    Review the most recent {String(pluralEvaluationPropertyName).toLowerCase()} you&apos;ve completed for a snapshot of your status.
                </Typography>
            </Grid>

            {/* Search and Filter Section */}
            <Grid
                item
                sm={4}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center', // Center align the inputs to match other components
                    gap: 1,
                }}
            >
                <Grid item sm={6}>
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{
                            '& .MuiInputLabel-root': {
                                left: '0%', // Shift label to center
                                // transform: 'translateX(-50%)', // Center the label horizontally
                                top: '-20%', // Shift label vertically to center
                                // transform: 'translate(-150%, -50%)', // Center both vertically and horizontally
                            },
                            '& .MuiInputLabel-shrink': {
                                transform: 'translate(0, -50%) scale(0.75)', // Keep the standard shrinking behavior
                                left: '0',
                                top: '0',
                            },
                            '& .MuiOutlinedInput-root': {
                                height: '40px', // Match the set height
                            },
                        }}
                        InputLabelProps={{
                            shrink: false, // Prevents the label from shrinking on focus or with input
                        }}
                    />
                </Grid>
                <Grid item sm={6}>
                    <Select
                        value={statusType}
                        onChange={(e) => setStatusType(e.target.value)}
                        displayEmpty
                        fullWidth
                        sx={{
                            height: '40px', // Match the fixed height of the TextField
                            '& .MuiOutlinedInput-root': {
                                height: '100%', // Match the set height
                            },
                        }}
                    >
                        <MenuItem value="">All Statuses</MenuItem>
                        {/* <MenuItem value="Draft">Draft</MenuItem> */}
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="Results Review">Results Review</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                    </Select>
                </Grid>
            </Grid>

            {/* Table Section */}
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: '14px !important', fontWeight: '500 !important', color: '#6A6A6A !important' }}>No</TableCell>
                            <TableCell sx={{ fontSize: '14px !important', fontWeight: '500 !important', color: '#6A6A6A !important' }}>Name</TableCell>
                            <TableCell sx={{ fontSize: '14px !important', fontWeight: '500 !important', color: '#6A6A6A !important' }}>Status</TableCell>
                            <TableCell sx={{ fontSize: '14px !important', fontWeight: '500 !important', color: '#6A6A6A !important' }}>Updated Date</TableCell>
                            <TableCell align="center" sx={{ fontSize: '14px !important', fontWeight: '500 !important', color: '#6A6A6A !important' }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAssessments.map((survey, index) => (
                            <React.Fragment key={survey.id}>
                                <TableRow key={survey.id} sx={{ height: '48px !important' }}>
                                    {/* No */}
                                    <TableCell sx={{ fontWeight: 500, fontSize: '16px', color: '#233264', padding: '8px 16px', }}>{index + 1}.</TableCell>

                                    {/* Name */}
                                    <TableCell onClick={() => toggleRowExpansion(index, survey.uuid)} sx={{ padding: '8px 16px', cursor: 'pointer' }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: '12px', color: '#6A6A6A' }}>
                                            {survey.assessment_definition.name}
                                        </Typography>
                                        <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#2E3645' }}>{survey.name}</Typography>
                                    </TableCell>

                                    {/* Status */}
                                    <TableCell sx={{ padding: '8px 16px' }}>
                                        {survey.status === AssessmentStatus.IN_PROGRESS ? (
                                            <Typography
                                                sx={{
                                                    display: 'inline-block',
                                                    fontSize: '14px',
                                                    color: '#D82C2C', // Pending style
                                                    backgroundColor: '#fff4f2',
                                                    padding: '4px 12px',
                                                    borderRadius: '4px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                In Progress
                                            </Typography>
                                        ) : survey.status === AssessmentStatus.RESULTS_REVIEW ? (
                                            <Typography
                                                sx={{
                                                    display: 'inline-block',
                                                    fontSize: '14px',
                                                    color: '#4763C7', // Common color for Review and Closed
                                                    backgroundColor: '#eef2fe',
                                                    padding: '4px 12px',
                                                    borderRadius: '4px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                In Review
                                            </Typography>
                                        ) : survey.status === AssessmentStatus.CLOSED ? (
                                            <Typography
                                                sx={{
                                                    display: 'inline-block',
                                                    fontSize: '14px',
                                                    color: '#4763C7', // Common color for Review and Closed
                                                    backgroundColor: '#eef2fe',
                                                    padding: '4px 12px',
                                                    borderRadius: '4px',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                Complete
                                            </Typography>
                                        ) : null}
                                    </TableCell>

                                    {/* Date */}
                                    <TableCell sx={{ padding: '8px 16px', }}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            color: '#2E3645',
                                            fontWeight: 500,

                                        }}>
                                            {moment(survey.last_updated_at).format('MMMM Do, YYYY')}</Typography>
                                    </TableCell>

                                    {/* Action */}
                                    <TableCell align="center" sx={{ padding: '8px 16px' }}>
                                        {(survey.status === AssessmentStatus.IN_PROGRESS || survey.status === AssessmentStatus.CLOSED) && (
                                            <Tooltip title="Actions">
                                                <IconButton
                                                    onClick={(e) => handleMenuOpen(e, survey)}
                                                    size="small"
                                                    sx={{
                                                        color: '#7F849B',
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                                {/* Expanded Row */}
                                < TableRow >
                                    <TableCell colSpan={5} sx={{ padding: 0 }}>
                                        <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                            <Box sx={{ padding: 2, backgroundColor: '#f9f9f9' }}>
                                                {loading ? (
                                                    // Display loading spinner while loading
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <CircularProgress size={24} />
                                                    </Box>
                                                ) : error ? (
                                                    // Display error message if an error occurs
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#828282' }}>
                                                            This {String(singularEvaluationPropertyName).toLowerCase()} doesn&apos;t have any data to be scored at the moment
                                                        </Typography>
                                                    </Box>
                                                ) : assessmentScores ? (
                                                    // Display the AssessmentScoreDetailsDropdown when scores are available
                                                    <AssessmentScoreDetailsDropdown
                                                        assessmentScores={assessmentScores}
                                                        branding={branding}
                                                    />
                                                ) : null}
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Footer Section */}
            <Box sx={{ marginLeft: 1, marginTop: 2, display: 'flex', gap: 1, alignItems: 'center', }}>
                <CheckmarkIcon />
                <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '12px', color: '#4763C7' }}>
                    You have taken {surveys.length} {String(pluralEvaluationPropertyName).toLowerCase()} in total
                </Typography>
            </Box>

            {/* Action Menu */}
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {selectedSurvey?.status === AssessmentStatus.IN_PROGRESS && (
                    <MenuItem onClick={handleCopyLink}>
                        <ListItemIcon>
                            <LinkIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy link</ListItemText>
                    </MenuItem>
                )}

                {selectedSurvey?.status === AssessmentStatus.CLOSED && (
                    <MenuItem onClick={handleOpenReport}>
                        <ListItemIcon>
                            <OpenInNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Open report</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </Grid >
    );
};

export default CompletedSurveys;
