import React from 'react';

interface BarGraphScoreArrowIconProps {
    width?: string;
    height?: string;
    color?: string;
}

function BarGraphScoreArrowIcon({
    width = '49px',
    height = '28px',
    color = '#2FA9E3',
}: BarGraphScoreArrowIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 49 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.8853 0.79248H3.06063C0.42691 0.79248 -0.839865 4.02279 1.09178 5.8131L23.3173 26.4123C24.4591 27.4706 26.2333 27.4369 27.3341 26.3361L47.9333 5.73686C49.7579 3.91226 48.4657 0.79248 45.8853 0.79248Z"
                fill={color}
            />
        </svg>

    );
}

export default BarGraphScoreArrowIcon;
