import React, { useEffect } from 'react';
import { RootStoreType } from '../../../redux/store.type';
import { useAppSelector } from '../../../redux/store';
import useGetAccount from '../../Assessments/hooks/useGetAccount';
import { CustomAccountBranding } from '../../Branding/type';
import { LoginResponse } from '../../Auth/reducers/authenticateUser';
import { Avatar, Box, Card, CircularProgress, Grid, Typography } from '@mui/material';
import CompletedSurveys from './components/completedSurverys';
import useGetAccountAssessments from '../../Assessments/hooks/useGetAccountAssessments';
import useGetAccountAssessmentDefinitionsScores from '../../Assessments/hooks/useGetAccountAssessmentDefinitionsScores';
import { AccountAssessmentDefinitionsScoresDetails, AccountAssessmentListDetail } from '../../Assessments/type';
import { useDispatch, useSelector } from 'react-redux';
import AssessmentCategoryScoreTabsCard from './components/assessmentCategoryScoreTabsCard';
import { resetAccountAssessmentDefinitionsScoresState } from '../../Assessments/reducers/getAccountAssessmentDefinitionsScores';
import { resetAccountAssessmentListState } from '../../Assessments/reducers/getAccountAssessments';

interface ClientDashboardProps {
  branding: CustomAccountBranding;
  loggedInUser: LoginResponse;
}

const ClientDashboard: React.FC<ClientDashboardProps> = ({ branding, loggedInUser }) => {
  const dispatch = useDispatch();
  const getAccountResponse = useGetAccount();
  const { accountAssessmentsError, accountAssessmentsLoading, getAllAccountAssessments } = useGetAccountAssessments();
  const { accountAssessmentDefinitionsError, accountAssessmentDefinitionsLoading, getAllAccountAssessmentDefinitionsScores } = useGetAccountAssessmentDefinitionsScores();

  const accountAssessments = useSelector(
    (state: RootStoreType) =>
      state.assessmentReducers.getAccountAssessmentsReducer
        .assessments as AccountAssessmentListDetail[],
  );

  const accountAssessmentDefinitionsScores = useSelector(
    (state: RootStoreType) =>
      state.assessmentReducers.getAccountAssessmentDefinitionsScoresReducer
        .assessmentDefinitions as AccountAssessmentDefinitionsScoresDetails,
  );

  const pluralEvaluationPropertyName = branding.evaluation_property_name_translation ? branding.evaluation_property_name_translation : "Assessments";
  const singularEvaluationPropertyName = pluralEvaluationPropertyName.endsWith("s")
    ? pluralEvaluationPropertyName.slice(0, -1) // Remove the last character
    : pluralEvaluationPropertyName;

  useEffect(() => {
    if (loggedInUser.account_id) {
      getAccountResponse.getAccount(`${loggedInUser.account_id as string}`);
    }
  }, [loggedInUser]);

  // const accountDetails = useAppSelector((state: RootStoreType) => {
  //   return state.assessmentReducers.accountInfoReducer.accountDetail;
  // });

  // const primaryContact = accountDetails?.account_contacts.find(contact => contact.is_primary_contact === true);

  useEffect(() => {
    dispatch(resetAccountAssessmentListState());
    dispatch(resetAccountAssessmentDefinitionsScoresState());
    getAllAccountAssessments();
    getAllAccountAssessmentDefinitionsScores();
  }, []);

  return (
    <Grid container sx={{ padding: '22px 28px', fontFamily: 'Inter', }}>
      <Grid item md={12}>
        <Typography sx={{
          fontFamily: 'Inter',
          fontSize: '24px',
          color: '#2E3645',
          fontWeight: 700,
          marginBottom: 1
        }}>
          Overview
        </Typography>
        <Box sx={{
          "& hr": {
            border: "none",
            borderTop: "1px solid #CCD2F3",
          },
        }}>
          <hr />
        </Box>
      </Grid>
      <Grid item md={12}>
        <Typography sx={{
          fontFamily: 'Inter',
          fontSize: '34px',
          color: '#233264',
          fontWeight: 600,
          marginTop: 2,
          marginBottom: 1
        }}>
          Hello {loggedInUser?.first_name},
        </Typography>
      </Grid>

      <Grid item md={12}>
        {accountAssessmentsLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '460px',
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          !accountAssessmentsError && accountAssessments ? (
            <CompletedSurveys surveys={accountAssessments} branding={branding} />
          ) : !accountAssessmentsError && !accountAssessments ? (
            <Card sx={{ padding: 2, boxShadow: 3, borderRadius: 2, fontFamily: 'Inter' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                No {String(pluralEvaluationPropertyName).toLowerCase()} have been published for this account.
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                Please contact your MEP to learn how to get one started today!
              </Box>
            </Card>
          ) : (
            <Card sx={{ padding: 2, boxShadow: 3, borderRadius: 2, fontFamily: 'Inter' }}>
              <Box>Unable to load account {String(pluralEvaluationPropertyName).toLowerCase()} at this time.</Box>
            </Card>
          )
        )}
      </Grid>
      <Grid item md={12} mt={4}>
        {accountAssessmentDefinitionsLoading ? (
          <Card sx={{ padding: 2, boxShadow: 3, borderRadius: 2, fontFamily: 'Inter' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '460px' }}>
              <CircularProgress size={24} />
            </Box>
          </Card>
        ) : (
          !accountAssessmentDefinitionsError && accountAssessmentDefinitionsScores ? (
            <AssessmentCategoryScoreTabsCard
              assessmentDefinitions={accountAssessmentDefinitionsScores.assessment_definitions}
              branding={branding}
            />
          ) : (
            <Card sx={{ padding: 2, boxShadow: 3, borderRadius: 2, fontFamily: 'Inter' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '460px' }}>
                Unable to load account {String(pluralEvaluationPropertyName).toLowerCase()} at this time.
              </Box>
            </Card>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default ClientDashboard;
