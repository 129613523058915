import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { AccountAssessmentDefinitionsScoresDetails } from '../type';
import { EndPoints } from '../../../constants/apiUrls';

export interface AccountAssessmentDefinitionsScoresReducer {
  status: RequestStatus;
  assessmentDefinitions: AccountAssessmentDefinitionsScoresDetails | null;
}
const initialState: AccountAssessmentDefinitionsScoresReducer = {
  status: RequestStatus.Init,
  assessmentDefinitions: null,
};

export const getAccountAssessmentDefinitionsScores = createAsyncThunk<AccountAssessmentDefinitionsScoresDetails>(
  '/getAccountAssessmentDefinitionsScores',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<AccountAssessmentDefinitionsScoresDetails> = await axiosV2Instance.get(
        EndPoints.ACCOUNT_ASSESSMENT_DEFINITIONS_SCORES
      );
      return response.data; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const getAccountAssessmentDefinitionsScoresSlice = createSlice({
  name: '/getAccountAssessmentDefinitionsScores',
  initialState,
  reducers: {
      resetAccountAssessmentDefinitionsScoresState: (state) => {
        Object.assign(state, initialState);
      },},
  extraReducers: (builder) => {
    builder.addCase(getAccountAssessmentDefinitionsScores.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getAccountAssessmentDefinitionsScores.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.assessmentDefinitions = action.payload;
    });
    builder.addCase(getAccountAssessmentDefinitionsScores.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const { resetAccountAssessmentDefinitionsScoresState } = getAccountAssessmentDefinitionsScoresSlice.actions;

export default getAccountAssessmentDefinitionsScoresSlice.reducer;