import { useDispatch } from 'react-redux';
import { createClient } from '../reducers/createClient';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface useCreateClientProps {
  error: unknown,
  loading: boolean,
  createAccount: (createClientRequest: FormData) => Promise<void>;
}

const useCreateClient = (): useCreateClientProps => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const createAccount = async (createClientRequest: FormData) => {
    try {
      setLoading(true);
      const resultAction = await dispatch(createClient(createClientRequest));
      if (createClient.fulfilled.match(resultAction)) {
        setLoading(false);
        toast.success('Account Created Successfully!');
        navigate(`/account-detail-view/${resultAction.payload.data.id}`);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { createAccount, loading, error };
};

export default useCreateClient;
