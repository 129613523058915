import { Moment } from 'moment';
import { Order } from './interfaces';

function descendingComparator<T extends object>(a: T, b: T, orderBy: keyof T) {
  // Handle cases where orderBy key doesn't exist on objects
  if (!(orderBy in a) || !(orderBy in b)) return 0;

  // Handle null values
  if (a[orderBy] === null && b[orderBy] === null) return 0;
  if (a[orderBy] === null) return 1;
  if (b[orderBy] === null) return -1;

  // Handle date fields specifically
  if (orderBy.toString().includes('date') || orderBy.toString().includes('_at')) {
    const dateA = new Date(a[orderBy] as string).getTime();
    const dateB = new Date(b[orderBy] as string).getTime();
    
    if (dateB < dateA) return -1;
    if (dateB > dateA) return 1;
    return 0;
  }

  // Handle regular values
  if (a[orderBy] !== undefined && b[orderBy] !== undefined && b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (a[orderBy] !== undefined && b[orderBy] !== undefined && b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean | Moment | null},
  b: { [key in Key]: number | string | boolean | Moment | null},
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T extends object>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export { descendingComparator, getComparator, stableSort };
