import { Box, Grid } from '@mui/material';
import React from 'react';
import {
    Typography19px600,
    Typography12px400,
} from '../../../../../styles/Global.styles';
import { SaveButton } from '../../../../Accounts/CreateClient/CreateClient.styles';
import { store } from '../../../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { QuestionCategoryResult, ScoresByCategoryAssessmentDetails, ResultChartType } from '../../../type';
import BarChart from './BarChart';
import ChallengesAndOpportunitiesComponent from './ChallengesAndOpportunities';
import { clearAssessmentDetails } from '../../../reducers/getAssessmentDetails'
import GaugeChart from '../../../../Evaluations/EvaluationAnalysis/EvaluationAnalysisContainer/GaugeChart'

const AssessmentAnalysis = ({ assessmentCategoryScoreDetails }: { assessmentCategoryScoreDetails: ScoresByCategoryAssessmentDetails }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const challengesAndOfferings = assessmentCategoryScoreDetails.scores_by_category.map((category) => {
        return (
            <ChallengesAndOpportunitiesComponent
                key={category.category_id.toString()}
                title={category.category_name}
                challengesThreats={category.category_analysis_notes.challenges_threats || []}
                possibleOfferings={category.category_analysis_notes.possible_offerings || []}
            />
        );
    });

    //Calculate Score Stats for the entire Assessment
    function calculateCombinedMaxScoresWithAverage(assessmentCategoryScoreDetails: ScoresByCategoryAssessmentDetails): QuestionCategoryResult {
        let totalQuestions = 0;
        let totalMaxCumulativeScore = 0;
        let totalCumulativeAverageScore = 0;

        assessmentCategoryScoreDetails.scores_by_category.forEach(category => {
            totalQuestions += category.question_count;
            totalMaxCumulativeScore += Number(category.category_max_possible_score);
            totalCumulativeAverageScore += category.average_category_score;
        });

        // Return the combined result
        return {
            name: "Total Survey Score",
            number_of_questions: totalQuestions,
            max_cumulative_score: totalMaxCumulativeScore,
            cumulative_average_score: totalCumulativeAverageScore,
        };
    }

    // function calculateMaxScoresWithAverage(assessmentCategoryScoreDetails: ScoresByCategoryAssessmentDetails): QuestionCategoryResult[] {
    //     const results: QuestionCategoryResult[] = [];

    //     assessmentCategoryScoreDetails.scores_by_category.forEach(category => {
    //         results.push({
    //             name: category.category_name,
    //             id: category.category_id,
    //             number_of_questions: category.question_count,
    //             max_cumulative_score: Number(category.category_max_possible_score),
    //             cumulative_average_score: category.average_category_score
    //         });
    //     });

    //     return results;
    // }

    const cumulativeBarChart = React.useMemo(() => {
        if (assessmentCategoryScoreDetails.result_chart_type == ResultChartType.BAR_CHART) {
            const questionCategoryResult = calculateCombinedMaxScoresWithAverage(assessmentCategoryScoreDetails);

            return (
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '1em 0', gap: '1em' }}
                    key={questionCategoryResult.name}
                >
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5em' }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '1em',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.424px',
                        }}>
                            {questionCategoryResult.name}
                        </div>
                        {/* <div style={{
                        display: 'flex',
                        padding: '3.026px 15.13px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1em',
                        borderRadius: '6.052px',
                        background: '#EFF7FB',
                    }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '0.8em',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.303px',
                        }}>
                            {questionCategoryResult.number_of_questions} Questions
                        </div>
                    </div> */}
                    </div>

                    <BarChart
                        cumulative_average_score={Number(questionCategoryResult.cumulative_average_score)}
                        max_cumulative_score={Number(questionCategoryResult.max_cumulative_score)}
                        bar_height='51px'
                        bar_width='650px'
                        bar_color='#053d58'
                    />
                </Grid>
            );
        }
    }, [assessmentCategoryScoreDetails]);

    const barCharts = React.useMemo(() => {
        if (assessmentCategoryScoreDetails.result_chart_type == ResultChartType.BAR_CHART) {
            // Iterate through the results from calculateMaxScoresWithAverage
            return assessmentCategoryScoreDetails.scores_by_category.map((category_score, index) => (
                <Grid
                    item
                    key={category_score.category_name}
                    sm={12}
                >
                    {index !== 0 && <hr style={{
                        border: 'none', borderTop: '2px solid #2FA9E3', width: '90%', marginLeft: '5%'
                    }}
                    />}
                    <div
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '1em 5% 1em 5%' }}
                    >
                        {/* Label portion of each category row  */}
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                            <div style={{
                                color: '#6A6A6A',
                                fontFamily: 'Inter',
                                fontSize: '1em',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '27.233px',
                                letterSpacing: '-0.424px',
                            }}>
                                {category_score.category_name}
                            </div>
                            {/* <div style={{
                            display: 'flex',
                            padding: '3.026px 15.13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1em',
                            borderRadius: '6.052px',
                            background: '#EFF7FB',
                        }}>
                            <div style={{
                                color: '#6A6A6A',
                                fontFamily: 'Inter',
                                fontSize: '0.8em',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '27.233px',
                                letterSpacing: '-0.303px',
                            }}>
                                {questionCategoryResult.number_of_questions} Questions
                            </div>
                        </div> */}
                        </div>

                        {/* BarChart portion of each category row  */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                        >
                            <BarChart
                                cumulative_average_score={Number(category_score.average_category_score)}
                                max_cumulative_score={Number(category_score.category_max_possible_score)}
                            />
                        </div>
                    </div>
                </Grid >
            ));
        }
    }, [assessmentCategoryScoreDetails]);

    const gasGuageCharts = React.useMemo(() => {
        if (assessmentCategoryScoreDetails.result_chart_type !== ResultChartType.BAR_CHART) {
            return assessmentCategoryScoreDetails.scores_by_category.map((category_score, index) => (
                <Grid item sm={12} md={6} lg={4} xl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }} key={category_score.category_id}>
                    <GaugeChart
                        name={category_score.category_name}
                        score={category_score.average_category_score}
                        maxScore={Number(category_score.category_max_possible_score)}
                        minScore={Number(category_score.category_min_possible_score)}
                    />
                </Grid>
            ));
        }
    }, [assessmentCategoryScoreDetails]);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingX: '20px',
                position: 'relative',
            }}
        >
            <Grid item sm={14} sx={{ display: "flex", justifyContent: 'end', marginTop: 0, paddingTop: 0 }}
            >
                <SaveButton
                    sx={{
                        paddingX: 2,
                        fontSize: '18px'
                    }}
                    onClick={() => {
                        store.dispatch(clearAssessmentDetails());
                        navigate(
                            `/evaluation-assessment-detail/${id || ''}/evaluation-assessment-analysis-score`,
                        );
                    }}
                >
                    {'Report Builder'}
                </SaveButton>
            </Grid>

            <Grid sx={{ marginTop: '1em' }}>
                {assessmentCategoryScoreDetails.result_chart_type == ResultChartType.BAR_CHART && (
                    <Grid
                        item
                        sx={{
                            borderRadius: '10px',
                            background: '#FFF',
                            boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                            minHeight: '200px',
                            marginBottom: '2em',
                            padding: '20px',
                            width: '100%',
                            border: '1px solid #E7E9F9',
                        }}
                    >
                        <Typography19px600>{'Current State Summary'}</Typography19px600>
                        <Typography12px400
                            sx={{
                                color: '#B5B5C3',
                                marginBottom: '20px'
                            }}
                        >
                            {'Overview of the total survey score'}
                        </Typography12px400>
                        <Grid container justifyContent="center" alignItems='center'>
                            {cumulativeBarChart}
                        </Grid>
                    </Grid>
                )}

                <Grid
                    item
                    marginBottom={'70px'}
                    sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                        minHeight: '200px',
                        marginBottom: '2em',
                        padding: '20px',
                        width: '100%',
                        border: '1px solid #E7E9F9',
                    }}
                >
                    <Typography19px600>{'Areas for Improvement by Category'}</Typography19px600>
                    <Typography12px400
                        sx={{
                            color: '#B5B5C3',
                            marginBottom: '20px'
                        }}
                    >
                        {'Low scoring statements for each category and their proposed solutions'}
                    </Typography12px400>
                    <Grid container spacing={1} justifyContent="center" alignContent="space-around">
                        {challengesAndOfferings}
                    </Grid>
                </Grid>


                <Grid
                    item
                    marginBottom={'70px'}
                    sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                        minHeight: '200px',
                        margin: 0,
                        padding: '20px',
                        width: '100%',
                        border: '1px solid #E7E9F9',
                    }}
                >
                    <Typography19px600>{'Survey Results by Category'}</Typography19px600>
                    <Typography12px400
                        sx={{
                            color: '#B5B5C3',
                            marginBottom: '20px'
                        }}
                    >
                        {'Brief overview of results by category'}
                    </Typography12px400>

                    {assessmentCategoryScoreDetails.result_chart_type == ResultChartType.BAR_CHART && (
                        <Grid container justifyContent="center" alignItems="center">
                            {barCharts}
                        </Grid>
                    )}
                    {assessmentCategoryScoreDetails.result_chart_type !== ResultChartType.BAR_CHART && (
                        <Grid container display="flex" justifyContent="center" alignItems="center" spacing={2}>
                            {gasGuageCharts}
                        </Grid>
                    )}
                </Grid>





            </Grid>
        </Grid>
    );
};

export default AssessmentAnalysis;
