import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { PostClientUser, ClientUserRequest } from '../reducers/postClientUser';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface usePostClientUserProps {
  loading: boolean;
  error: AxiosError | null;
  postClientUser: (ClientUserRequest) => Promise<any>;
}

const usePostClientUser = (): usePostClientUserProps => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);

      const postClientUser = async (clientUserPayload : ClientUserRequest) => {
          try {
              const resultAction = await dispatch(PostClientUser(clientUserPayload));
              if (PostClientUser.fulfilled.match(resultAction)) {
                  setLoading(false);
              } else if (PostClientUser.rejected.match(resultAction)) {
                  setLoading(false);
                  setError(resultAction.payload as AxiosError);
              }
              return resultAction.payload
          } catch (err) {
              setLoading(false);
              setError(err as AxiosError);
          }
      };

  return { postClientUser, error, loading };
};

export default usePostClientUser;
