import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { getAssessmentScoreAnalysis } from '../reducers/getAssessmentScoreAnalysis';

interface accountAssessmentDefinitionsScoresProp {
    loading: boolean;
    error: unknown;
    getAssessmentScores: (assessmentUuid: string) => void;
}

const useGetAssessmentScoreAnalysis = (): accountAssessmentDefinitionsScoresProp => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const getAssessmentScores = async (assessmentUuid: string) => {
        setError(null);
        setLoading(true);
        try {
            const resultAction = await dispatch(getAssessmentScoreAnalysis(assessmentUuid));
            if (getAssessmentScoreAnalysis.fulfilled.match(resultAction)) {
                return { success: true, data: resultAction.payload };
            }
            throw new Error('Failed to fetch assessment scores');
        } catch (error) {
            setError(error as Error);
            return { success: false };
        } finally {
            setLoading(false)
        }
    };

    return { loading, error, getAssessmentScores };
};

export default useGetAssessmentScoreAnalysis;