import React from 'react';
import { Box, Grid, Paper, Typography, Chip } from '@mui/material';
import { TitleTypography, ValueTypography } from '../Overview.styles';
import { AccountContact } from '../../../../modules/Accounts/types';
import { Link } from 'react-router-dom';
import translation from '../../../../locales/en/translation.json';
import { useBranding } from '../../../../modules/Branding/reducers/getBranding';
import { SaveButton } from '../../../../modules/Accounts/CreateClient/CreateClient.styles';
import usePostClientUser from '../../../../modules/Auth/hooks/usePostClientUser';
import { ClientUserRequest } from '../../../../modules/Auth/reducers/postClientUser';
import { toast } from 'react-toastify';

const ContactCard = (prop: { account_contact: AccountContact[] }) => {
  const data = prop.account_contact;

  const branding = useBranding();
  const postClientUser = usePostClientUser();
  const isImpactWashington = branding?.company_name.toLowerCase().includes("washington");

  const onClick = async (item: AccountContact) => {
    if (!item.email || !item.account_id || !item.first_name) {
      toast.error('Cannot send the user an access request without both an Email and First Name');
      return;
    }

    const postUserRequest: ClientUserRequest = {
      email: item.email,
      account_id: item.account_id,
      first_name: item.first_name,
      last_name: item.last_name,
      profile_image: item.contact_photo
    }

    try {
      const response = await postClientUser.postClientUser(postUserRequest);

      // Check if the response indicates success
      if (response?.status === 200) {
        toast.success('Password Reset Email Sent');
      } else if (response?.status === 201) {
        toast.success('Account Activation Email Sent to New Client Account');
      } else if (response?.status === 429) {
        toast.error('Email Recently Sent. Please Wait Before Sending Another');
      } else if (response?.status === 403) {
        toast.error('Unable to Link Email to This Account');
      } else {
        toast.error('Error Sending User Access');
      }
    } catch (error: any) {
      // Generic fallback for other errors
      toast.error('Error Sending User Access');
    }
  }

  if (!isImpactWashington) {
    return (
      <Paper
        sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}
        elevation={3}
      >
        <Grid container spacing={2} minHeight={'240px'} marginTop={1}>
          <Grid item xs={12} md={2} lg={2}>
            <Box sx={{ padding: '0px 28px' }}>
              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#000',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                }}
              >
                Contacts
              </Typography>

              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#B5B5C3',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                Designate a primary point of Contact (POC) and multiple alternates
                for added awareness and management
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10} lg={10} paddingRight={'50px'}>
            {data.length > 0 ? (
              data.map((item, index) => (
                <Paper
                  sx={{ width: '100%', overflow: 'hidden', marginBottom: '10px' }}
                  key={index}
                >
                  <Grid container spacing={0}>
                    <Grid item md={12} lg={2}>
                      <Box sx={{ padding: '22px 28px' }}>
                        {item.contact_photo && (
                          <img
                            src={item.contact_photo}
                            alt=""
                            style={{
                              maxWidth: '100px',
                              width: 'auto',
                              height: 'auto',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={12} lg={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.name}</TitleTypography>
                            <ValueTypography>
                              {(item?.first_name && item?.last_name) ? `${item.first_name as string} ${item.last_name as string}` : item?.name ?? 'N/A'}
                            </ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.role}</TitleTypography>
                            <ValueTypography>{item.role || "N/A"}</ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.phone_no}</TitleTypography>
                            <ValueTypography>{item.phone || "N/A"}</ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.email}</TitleTypography>
                            {item.email ? (
                              <Link to={item.email} target="_blank">
                                <ValueTypography sx={{ color: 'rgba(18, 40, 242, 1)' }}>
                                  {item.email}
                                </ValueTypography>
                              </Link>
                            ) : (
                              <ValueTypography>N/A</ValueTypography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <TitleTypography>{translation.location}</TitleTypography>
                            <ValueTypography>{item.location || "N/A"}</ValueTypography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Box sx={{ padding: '22px 28px' }}>
                            <Chip
                              sx={{
                                borderRadius: '6px',
                                background: item.is_primary_contact
                                  ? 'rgba(18, 40, 242, 0.20)'
                                  : 'rgba(0, 219, 153, 0.2)',
                                color: '#000',
                                fontWeight: '600',
                                textAlign: 'center',
                              }}
                              label={item.is_primary_contact ? 'Primary' : 'Alternate'}
                              color="success"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} lg={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }
                    }>
                      <SaveButton
                        onClick={() => onClick(item)}
                        disabled={!item.email || !item.account_id || !item.first_name}
                      >
                        Send User Access
                      </SaveButton>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {!item.email || !item.first_name ? (
                          <Typography
                            sx={{ color: 'red', fontSize: '12px', marginTop: '8px', fontFamily: 'Inter', fontWeight: 500 }}
                          >
                            {item.email && !item.first_name && "First Name Required"}
                            {!item.email && item.first_name && "Email Required"}
                            {!item.email && !item.first_name && "Email and First Name Required"}
                          </Typography>
                        ) : null}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <Typography
                sx={{
                  color: '#A3A3A3',
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}
              >
                No records found.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (
      <Paper
        sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}
        elevation={3}
      >
        <Grid container spacing={2} minHeight={'240px'} marginTop={'35px'}>
          <Grid item xs={12} md={2} lg={2}>
            <Box sx={{ padding: '22px 28px' }}>
              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#000',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                }}
              >
                Contacts
              </Typography>

              <Typography
                color="primary"
                variant="subtitle1"
                sx={{
                  color: '#B5B5C3',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                Designate a primary point of Contact (POC) and multiple alternates
                for added awareness and management
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10} lg={10} paddingRight={'50px'}>
            {data.length > 0 ? (
              data.map((item, index) => (
                <Paper
                  sx={{ width: '100%', overflow: 'hidden', marginBottom: '10px' }}
                  key={index}
                >
                  <Grid container spacing={2} sx={{}}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.name}</TitleTypography>
                        <ValueTypography>
                          {(item?.first_name && item?.last_name) ? `${item.first_name as string} ${item.last_name as string}` : item?.name ?? ''}
                        </ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.role}</TitleTypography>
                        <ValueTypography>{item.role}</ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.phone_no}</TitleTypography>
                        <ValueTypography>{item.phone}</ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.email}</TitleTypography>
                        <Link to={item.email} target="_blank">
                          <ValueTypography sx={{ color: 'rgba(18, 40, 242, 1)' }}>
                            {item.email}
                          </ValueTypography>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Box sx={{ padding: '22px 28px' }}>
                        <TitleTypography>{translation.location}</TitleTypography>
                        <ValueTypography>{item.location}</ValueTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      {item.is_primary_contact ? (
                        <Box sx={{ padding: '22px 28px' }}>
                          <Chip
                            sx={{
                              borderRadius: '6px',
                              background: 'rgba(18, 40, 242, 0.20)',
                              color: '#000',
                              fontWeight: '600',
                              textAlign: 'center',
                            }}
                            label={'Primary'}
                            color="success"
                          />
                        </Box>
                      ) : (
                        <Box sx={{ padding: '22px 28px' }}>
                          <Chip
                            sx={{
                              borderRadius: '6px',
                              background: 'rgba(0, 219, 153, 0.2)',
                              color: '#000',
                              fontWeight: '600',
                              textAlign: 'center',
                            }}
                            label={'Alternate'}
                            color="success"
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <Typography
                sx={{
                  color: '#A3A3A3',
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}
              >
                No records found.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
};

export default ContactCard;
