/* eslint-disable indent */
import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import UserCard from './UserCard';
import { useAppSelector } from '../../../redux/store';
import {
  AssignAccountRolePayload,
} from '../reducers/assignAccountRole';
import { RootStoreType } from '../../../redux/store.type';
import AddIcon from '@mui/icons-material/Add';
import AddAccountModal from './AddAccountModal';
import useAccountSettingsUsers from '../hooks/useGetAccountSettingsUsers';
import useGetAllUsers from '../hooks/useGetAllUsers';
import { ApplicationUser } from '../types';
import usePostAccountRole from '../hooks/usePostAccountRole';
import useDeleteRole from '../hooks/useDeleteUser';
import translate from '../../../locales/translate';
import {
  Typography10px400,
  Typography12px500,
  Typography14px600,
} from '../../../styles/Global.styles';
import { AddUserCardGrid } from './UserCard.style';

const AccountSettings = (prop: { id: string, accountExecutiveNameTranslation: string }) => {
  const { loading, error, getAccountSettingsUsers } = useAccountSettingsUsers();
  const deleteAccountRoleResponse = useDeleteRole();
  const getUsersResponse = useGetAllUsers();
  const postAccountRoleResponse = usePostAccountRole();
  const [reloadKey, setReloadKey] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [role, setrole] = React.useState('support');
  const [user, setuser] = React.useState('initial');

  const handleChangeRole = (event: SelectChangeEvent) => {
    setrole(event.target.value as string);
  };
  const handleChangeUser = (event: SelectChangeEvent) => {
    setuser(event.target.value as string);
  };

  const submitForm = (userId) => {
    const requestPayload: AssignAccountRolePayload = {
      account_id: prop.id,
      role: role,
      user_id: userId,
    };
    setReloadKey((prev) => prev + 1);

    postAccountRoleResponse.postAccountRole([requestPayload]);
    setOpen(false);
  };

  const deleteUserRole = (userId: string) => {
    deleteAccountRoleResponse.deleteAccountRole({ account_setting_id: userId });
    setReloadKey((prev) => prev - 1);
  };

  useEffect(() => {
    if (
      !postAccountRoleResponse.loading &&
      !deleteAccountRoleResponse.loading
    ) {
      getAccountSettingsUsers(prop.id);
      setuser('initial');
    }
  }, [postAccountRoleResponse.loading, deleteAccountRoleResponse.loading]);

  const allUsers: ApplicationUser[] | null = useAppSelector(
    (state: RootStoreType) => {
      return state.allClientReducer.getAllUsersReducer.users;
    },
  );

  const userData: AccountSettingsUser[] | null = useAppSelector(
    (state: RootStoreType) => {
      return state.allClientReducer.accountSettingReducer.accountSetting;
    },
  );

  return loading &&
    error &&
    getUsersResponse.loading &&
    getUsersResponse.error &&
    !allUsers ? (
    <CircularProgress sx={{ margin: '20vh 48%' }} />
  ) : (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '22px',
        marginBottom: '10px',
        borderRadius: '10px',
        gap: '12px',
        background: 'var(--white-100, #FFF)',
        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
        border: '1px solid #E7E9F9',
      }}
    >
      {/* <Grid item sm={12} md={12} lg={2}> */}
      <Grid item sm={2} xs={12}>
        <Typography14px600>{prop.accountExecutiveNameTranslation}</Typography14px600>
        <Typography10px400
          sx={{
            color: '#B5B5C3',
            lineHeight: '15px',
          }}
        >
          {translate('designate_who_will_receive_update_notifications')}
        </Typography10px400>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        gap={1}
        sm={9}
        xs={12}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            margin: '10px 0',
          }}
        >
          <Typography12px500>{translate('primary')}</Typography12px500>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {userData &&
              userData
                .filter((user) => user.role === 'account_executive')
                .map((user) => {
                  return (
                    <UserCard
                      key={user.id}
                      removeUser={(userId: string) => {
                        deleteUserRole(userId);
                      }}
                      user={user}
                      accountExecutiveNameTranslation={prop.accountExecutiveNameTranslation}
                    />
                  );
                })}
            {userData &&
              userData.filter((user) => user.role === 'account_executive')
                .length === 0 && (
                <AddUserCardGrid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{
                    backgroundColor: 'rgba(18, 40, 242, 0.05)',
                    '&:hover': {
                      backgroundColor: 'rgba(18, 40, 242, 0.09)',
                    },
                  }}
                  onClick={() => {
                    setrole('account_executive');
                    setOpen(true);
                  }}
                >
                  {translate('add_person')}
                </AddUserCardGrid>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography12px500>{translate('support')}</Typography12px500>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {userData &&
              userData
                .filter((user) => user.role === 'support')
                .map((user) => {
                  return (
                    <UserCard
                      key={user.id}
                      removeUser={(userId: string) => deleteUserRole(userId)}
                      user={user}
                    />
                  );
                })}
            <AddUserCardGrid
              sx={{
                backgroundColor: 'rgba(0, 219, 153, 0.05)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 219, 153, 0.09)',
                },
              }}
              onClick={() => {
                setrole('support');
                setOpen(true);
              }}
            >
              {translate('add_person')}
            </AddUserCardGrid>
          </Grid>
        </Grid>
      </Grid>

      <AddAccountModal
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
        handleOpen={setOpen}
        is_executive={role === 'account_executive'}
        accountExecutiveNameTranslation={prop.accountExecutiveNameTranslation}
        onSubmit={(event) => {
          event.preventDefault();
          submitForm(event.target.user.value);
        }}
        form={
          <Box>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user}
                size="small"
                name="user"
                onChange={(e: SelectChangeEvent) => handleChangeUser(e)}
              >
                <MenuItem disabled selected value={'initial'}>
                  {translate('please_select_user')}
                </MenuItem>
                {allUsers &&
                  allUsers.map((user) => {
                    return (
                      <MenuItem key={user.id} value={user.id}>
                        {user.fullname}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        }
        key={1}
      />
    </Grid>
  );
};

export default AccountSettings;
export interface AccountSettingsUser {
  id: number | string;
  email: string;
  fullname: string;
  role: string;
  phone_number?: string;
  user_id: string | number;
}
