import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { storeKey } from '../constants';
import {
  CreateClientReducer,
  CreateClientResponse,
} from '../types';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { toast } from 'react-toastify';

const initialState: CreateClientReducer = {
  isCreateClientSuccess: null,
};

const createClientSlice = createSlice({
  name: 'postCreateClient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createClient.pending, (state) => {
      state.isCreateClientSuccess = false;
    });
    builder.addCase(createClient.fulfilled, (state) => {
      state.isCreateClientSuccess = true;
      // toast.success('Account Created Successfully!');
    });
    builder.addCase(createClient.rejected, (state, action) => {
      state.isCreateClientSuccess = false;
      const errorResponse = (action.payload as AxiosError).response?.data as {
        error: Record<string, string[]>;
      };

      let userFriendlyMessage = "An error occurred.";
      if (errorResponse?.error) {
        const firstErrorKey = Object.keys(errorResponse.error)[0];
        if (firstErrorKey) {
          userFriendlyMessage = capitalizeFirstLetter(errorResponse.error[firstErrorKey][0]);
        }
      }

      toast.error(userFriendlyMessage);
    });

    // Utility function to capitalize the first letter of a string
    const capitalizeFirstLetter = (message: string): string => {
      if (!message) return message;
      return message.charAt(0).toUpperCase() + message.slice(1);
    };
  },
});

export const createClient = createAsyncThunk<
  CreateClientResponse,
  FormData
>(
  `${storeKey}/postCreateClient`,
  async (createClientRequest: FormData, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<CreateClientResponse> =
        await axiosInstance.post(
          EndPoints.POST_CREATE_CLIENT,
          createClientRequest,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default createClientSlice.reducer;
