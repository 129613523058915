import React from 'react';

function CheckmarkIcon() {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.73 8.03L8.6075 4.1525L7.8375 3.3825L4.73 6.49L3.1625 4.9225L2.3925 5.6925L4.73 8.03ZM5.5 11C4.73916 11 4.02417 10.8555 3.355 10.5666C2.68583 10.2777 2.10375 9.88588 1.60875 9.39125C1.11375 8.89661 0.721967 8.31453 0.4334 7.645C0.144834 6.97546 0.000367363 6.26046 6.96202e-07 5.5C-0.00036597 4.73953 0.144101 4.02453 0.4334 3.355C0.7227 2.68547 1.11448 2.10338 1.60875 1.60875C2.10302 1.11412 2.6851 0.722333 3.355 0.4334C4.0249 0.144467 4.7399 0 5.5 0C6.2601 0 6.9751 0.144467 7.645 0.4334C8.31489 0.722333 8.89698 1.11412 9.39125 1.60875C9.88551 2.10338 10.2775 2.68547 10.5671 3.355C10.8568 4.02453 11.0011 4.73953 11 5.5C10.9989 6.26046 10.8544 6.97546 10.5666 7.645C10.2788 8.31453 9.88698 8.89661 9.39125 9.39125C8.89551 9.88588 8.31343 10.2778 7.645 10.5671C6.97656 10.8564 6.26156 11.0007 5.5 11Z" fill="#4763C7" />
        </svg>

    );
}

export default CheckmarkIcon;
