import React from 'react';
import BarGraphScoreArrowIcon from '../../../../../assets/svg-components/BarGraphScoreArrowIcon';

interface BarChartProps {
  cumulative_average_score: number;
  max_cumulative_score: number;
  business_size_average_score?: number;
  bar_height?: string;
  bar_width?: string;
  bar_color?: string;
  min_width?: string;
  font_size?: string;
  line_height?: string;
  letter_spacing?: string;
  margin_left?: string;
  arrow_color?: string;
  arrow_height?: string;
  arrow_width?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  cumulative_average_score,
  max_cumulative_score,
  business_size_average_score,
  bar_height = '30px',   // Default height
  bar_width = '300px',   // Default width
  bar_color = '#2FA9E3',  // Default bar color
  min_width = '375px', // Default min width
  font_size = '20px', // Default font_size
  line_height = '20px', // Default line_height
  letter_spacing = '-0.4px', // Default letter_spacing
  margin_left = '5px', // Default margin_left
  arrow_color = '#2FA9E3', // Default arrow color
  arrow_height = '28px', // Default arrow color
  arrow_width = '49px', // Default arrow color
}) => {
  // Calculate percentage values for the bar chart
  const progressPercentage = (cumulative_average_score / max_cumulative_score) * 100;
  const businessSizePercentage = business_size_average_score
    ? (business_size_average_score / max_cumulative_score) * 100
    : null;

  return (
    <div style={{ ...containerStyle, minWidth: min_width }}>
      {/* The bar container */}
      <div style={{ ...wholeBarStyle, width: bar_width, height: bar_height }}>
        {/* Render the arrow pointer only if business_size_average_score is provided */}
        {businessSizePercentage !== null && (
          <div
            style={{
              position: 'absolute',
              left: `${businessSizePercentage}%`, // Align arrow with the bar
              transform: 'translateX(-50%)', // Center the arrow
              top: '-18px', // Adjust the position to place it above or below the bar
              zIndex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center align the labels horizontally
                // position: 'absolute',
                // top: '-35px', // Adjust position above the arrow
                // transform: 'translateY(-50%)', // Center vertically on the arrow
              }}>
              <BarGraphScoreArrowIcon color={arrow_color} height={arrow_height} width={arrow_width}/>
              <div
                style={{
                  ...arrowLabelValueStyle,
                  fontSize: font_size,
                }}>
                {business_size_average_score?.toFixed(2)}
              </div>
              <div style={{
                ...arrowLabelTextStyle,
              }}>
                Industry Average
              </div>
            </div>
          </div>
        )}
        {/* The blue progress bar */}
        <div style={{ ...progressBarStyle, width: `${progressPercentage}%`, backgroundColor: bar_color }}>
          {/* Conditionally render the label inside or outside the progress bar */}
          {progressPercentage >= 25 ? (
            <span
              style={{
                ...labelStyle,
                fontSize: font_size,
                lineHeight: line_height,
                letterSpacing: letter_spacing,
                ...insideLabelStyle
              }}
            >
              {`${cumulative_average_score.toFixed(2)}`}
            </span>
          ) : null}
        </div>
        {/* Render the label outside if the progress is less than 25% */}
        {progressPercentage < 25 && (
          <span
            style={{
              ...labelStyle,
              fontSize: font_size,
              lineHeight: line_height,
              letterSpacing: letter_spacing,
              ...outsideLabelStyle,
              left: `${progressPercentage}%` // Position the label just outside the bar's end
            }}
          >
            {`${cumulative_average_score.toFixed(2)}`}
          </span>
        )}
      </div>
      {/* The max value label outside the bar */}
      <span style={{
        ...maxLabelStyle,
        marginLeft: margin_left,
        fontSize: font_size,
        lineHeight: line_height,
        letterSpacing: letter_spacing
      }}>
        {`${max_cumulative_score}`}
      </span>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: 'flex',        // Flexbox layout for side-by-side bar and max label
  alignItems: 'center',   // Align vertically centered
  // justifyContent: 'start',
  position: 'relative',
  // marginRight: '5em'   // Relative positioning for the container
};

// Correctly typed styles
const wholeBarStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#B3B3B3', // Light gray background for the bar
  overflow: 'visible',
  position: 'relative',   // Relative positioning for the container
};

const progressBarStyle: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: 'bold',
};

const labelStyle: React.CSSProperties = {
  fontWeight: '900',
  color: '#FFF',
  fontFamily: "Inter",
  fontStyle: "normal",
};

// Styles for the label when it is inside the bar (centered)
const insideLabelStyle: React.CSSProperties = {
  padding: '0 10%',
};

// Styles for the label when it needs to be outside the bar (to the right)
const outsideLabelStyle: React.CSSProperties = {
  position: 'absolute',
  color: '#053D58',
  fontWeight: 'bold',
  transform: 'translateX(10%)', // Small offset to ensure it's just outside
};

const maxLabelStyle: React.CSSProperties = {
  color: '#053D58',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
};

const arrowLabelValueStyle: React.CSSProperties = {
  position: 'absolute',
  top: '-28px',
  fontWeight: 800,
  fontSize: '20px',
  color: '#053D58',
};

const arrowLabelTextStyle: React.CSSProperties = {
  position: 'absolute',
  top: '-48px',
  fontWeight: 600,
  fontSize: '14px',
  color: '#2E3645',
  // transform: 'translateX(-50%)',
  whiteSpace: 'nowrap', // Prevent text from wrapping
  overflow: 'hidden', // Prevent overflowing content
  textOverflow: 'ellipsis',
};

export default BarChart;
