import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { getAccountAssessments } from '../reducers/getAccountAssessments';

interface accountAssessmentsListProp {
  accountAssessmentsLoading: boolean;
  accountAssessmentsError: unknown;
  getAllAccountAssessments: () => void;
}

const useGetAccountAssessments = (): accountAssessmentsListProp => {
  const dispatch = useDispatch<AppDispatch>();
  const [accountAssessmentsLoading, setLoading] = useState(true);
  const [accountAssessmentsError, setError] = useState<unknown>();

  const getAllAccountAssessments = async () => {
    try {
      const resultAction = await dispatch(
        getAccountAssessments(),
      );
      if (getAccountAssessments.fulfilled.match(resultAction)) {
        setLoading(false);
      }
      return resultAction;
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { accountAssessmentsLoading, accountAssessmentsError, getAllAccountAssessments };
};

export default useGetAccountAssessments;