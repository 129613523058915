import { combineReducers } from '@reduxjs/toolkit';
import activateUserReducer, { Status } from './activateUser';
import authenticateUser, { AuthResponse } from './authenticateUser';
import getUserSummary, { UserSummaryInitialState } from '../../Dashboard/reducers/getUserSummary';
import logoutReducer, { LogoutSliceType } from './logout';
import createClientUserReducer, { ClientUserCreationReducer } from './postClientUser';
import forgetPasswordReducer, {
  ForgetPasswordSliceType,
} from './forgetPassword';

export default combineReducers<{
  user: AuthResponse;
  userSummary: UserSummaryInitialState;
  activateUser: Status;
  forgetPasswordReducer: ForgetPasswordSliceType;
  logoutReducer: LogoutSliceType;
  createClientUserReducer: ClientUserCreationReducer;
}>({
  user: authenticateUser,
  userSummary: getUserSummary,
  activateUser: activateUserReducer,
  forgetPasswordReducer,
  logoutReducer,
  createClientUserReducer,
});
