import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { getAccountAssessmentDefinitionsScores } from '../reducers/getAccountAssessmentDefinitionsScores';

interface accountAssessmentDefinitionsScoresProp {
  accountAssessmentDefinitionsLoading: boolean;
  accountAssessmentDefinitionsError: unknown;
  getAllAccountAssessmentDefinitionsScores: () => void;
}

const useGetAccountAssessmentDefinitionsScores = (): accountAssessmentDefinitionsScoresProp => {
  const dispatch = useDispatch<AppDispatch>();
  const [accountAssessmentDefinitionsLoading, setLoading] = useState(false);
  const [accountAssessmentDefinitionsError, setError] = useState<Error | null>(null);

  const getAllAccountAssessmentDefinitionsScores = async () => {
    setError(null);
    setLoading(true);
    try {
      const resultAction = await dispatch(getAccountAssessmentDefinitionsScores());
      if (getAccountAssessmentDefinitionsScores.fulfilled.match(resultAction)) {
        return { success: true, data: resultAction.payload };
      }
      throw new Error('Failed to fetch account scores');
    } catch (error) {
      setError(error as Error);
      return { success: false };
    } finally {
      setLoading(false)
    }
  };

  return { accountAssessmentDefinitionsLoading, accountAssessmentDefinitionsError, getAllAccountAssessmentDefinitionsScores };
};

export default useGetAccountAssessmentDefinitionsScores;