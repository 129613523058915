import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Pagination,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import {
  EnhancedTableProps,
  HeadCell,
} from '../Accounts/ClientList/interfaces';
import { Client } from '../../modules/Accounts/types';


const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  { id: 'website', numeric: false, disablePadding: true, label: 'Website' },
  { id: 'city', numeric: false, disablePadding: true, label: 'City' },
  {
    id: 'last_evaluation_date',
    numeric: false,
    disablePadding: true,
    label: 'Last Evaluation',
  },
  {
    id: 'last_updated_at',
    numeric: false,
    disablePadding: true,
    label: 'Last Updated',
  },
  { id: 'is_active', numeric: false, disablePadding: true, label: 'Status' },
];

export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Client) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow
        sx={{
          '& .MuiTableCell-root': {
            color: '#B5B5C3',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.36px',
            borderBottom: 'none',
          },
          '& .MuiTableSortLabel-root': {
            color: '#B5B5C3',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.36px',
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left">&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  pageCount: number;
  currentPage: number;
  currentStatus: string;
  handleStatus: (statusType: string) => void;
  handlePage: (pageNumber: number) => void;
  order: 'asc' | 'desc';
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;
  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    props.handleStatus(event.target.value);
  };

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiToolbar-root': {
          paddingLeft: '0px !important',
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      <Grid container>
        {numSelected > 0 ? (
          <Grid item xs={12} sm={12} md={2}>
            <Typography
              sx={{ maxWidth: '300px' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={4} sm={3} md={2}>
            <Box>
              <FormControl fullWidth>
                <Select
                  sx={{
                    color: '#1C1C1C',
                    fontWeight: 600,
                    lineHeight: '20px',
                    '& .MuiSelect-select': {
                      border: 'none',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '& .MuiSelect-icon': {
                      color: 'lightgrey',
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.currentStatus}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="All Clients">All Accounts</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        <Grid item xs={12} sm={10}>
          <Pagination
            sx={{
              float: 'right',
              boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.03)',
              borderRadius: '20px',
              '& .MuiPaginationItem-root': {
                borderColor: 'primary.main',
                '&.Mui-selected': {
                  color: 'primary.main',
                  borderColor: 'red',
                  background: 'white',
                },
              },
            }}
            onChange={(_, page) => {
              props.handlePage(page);
            }}
            page={props.currentPage}
            count={props.pageCount}
            size="large"
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
}
