import React from 'react';

function BarGraphIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5556 0.5H2.44444C1.1 0.5 0 1.6 0 2.94444V20.0556C0 21.4 1.1 22.5 2.44444 22.5H19.5556C20.9 22.5 22 21.4 22 20.0556V2.94444C22 1.6 20.9 0.5 19.5556 0.5ZM6.11111 17.6111C5.43889 17.6111 4.88889 17.0611 4.88889 16.3889V10.2778C4.88889 9.60556 5.43889 9.05556 6.11111 9.05556C6.78333 9.05556 7.33333 9.60556 7.33333 10.2778V16.3889C7.33333 17.0611 6.78333 17.6111 6.11111 17.6111ZM11 17.6111C10.3278 17.6111 9.77778 17.0611 9.77778 16.3889V6.61111C9.77778 5.93889 10.3278 5.38889 11 5.38889C11.6722 5.38889 12.2222 5.93889 12.2222 6.61111V16.3889C12.2222 17.0611 11.6722 17.6111 11 17.6111ZM15.8889 17.6111C15.2167 17.6111 14.6667 17.0611 14.6667 16.3889V13.9444C14.6667 13.2722 15.2167 12.7222 15.8889 12.7222C16.5611 12.7222 17.1111 13.2722 17.1111 13.9444V16.3889C17.1111 17.0611 16.5611 17.6111 15.8889 17.6111Z" fill="#233264" />
        </svg>
    );
}

export default BarGraphIcon;
