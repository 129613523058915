import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { useBranding } from '../Branding/reducers/getBranding';
import { FeatureFlags } from '../Branding/type';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../redux/store.type';
import AccountExecutiveDashboard from './AccountExecutiveDashboard/AccountExecutiveDashboard';
import ClientDashboard from './Client/ClientDashboard'

const Dashboard = () => {
    const branding = useBranding();
    const [loading, setLoading] = useState(true);
    const [dashboardAccess, setDashboardAccess] = useState<boolean | null>(null);
    const navigate = useNavigate();

    const loggedInUser = useSelector((state: RootStoreType) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return state.authReducers.user.currentUser;
    });

    useEffect(() => {
        if (branding !== null) {
            setDashboardAccess(branding?.active_feature_flags?.[FeatureFlags.DASHBOARD] === true);
            setLoading(false);
        }
    }, [branding]);

    useEffect(() => {
        if (!loading &&
            (!dashboardAccess ||
                // Temporarily have admin and AE reroute to accounts since they do not have a dashboard set up
                (loggedInUser?.user_type?.includes('admin') ||
                    loggedInUser?.user_type?.includes('account_executive')))) {
            navigate('/account-list');
        }
    }, [dashboardAccess, loggedInUser, navigate]);

    if (loading || !branding || !loggedInUser) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        // Only admin and account_executive can see admin page
        !loggedInUser?.user_type?.includes('admin') &&
        !loggedInUser?.user_type?.includes('account_executive')
    ) ? (
        <ClientDashboard
            branding={branding}
            loggedInUser={loggedInUser}
        />
    ) : (
        <AccountExecutiveDashboard />
    );
};

export default Dashboard;
