import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { AssessmentScoresDetail } from '../type';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentScoreAnalysisReducer {
  status: RequestStatus;
  assessmentScores: AssessmentScoresDetail | null;
}
const initialState: AssessmentScoreAnalysisReducer = {
  status: RequestStatus.Init,
  assessmentScores: null,
};

export const getAssessmentScoreAnalysis = createAsyncThunk<AssessmentScoresDetail, string>(
  '/getAssessmentScoreAnalysis',
  async (assessmentId, thunkAPI) => {
    try {
      const response: AxiosResponse<AssessmentScoresDetail> = await axiosV2Instance.get(
        `${EndPoints.ASSESSMENTS}/${assessmentId}/${EndPoints.ASSESSMENT_SCORES}`
      );
      return response.data; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const getAssessmentScoreAnalysisSlice = createSlice({
  name: '/getAssessmentScore',
  initialState,
  reducers: {
      resetAssessmentScoreAnalysisState: (state) => {
        Object.assign(state, initialState);
      },},
  extraReducers: (builder) => {
    builder.addCase(getAssessmentScoreAnalysis.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getAssessmentScoreAnalysis.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.assessmentScores = action.payload;
    });
    builder.addCase(getAssessmentScoreAnalysis.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const { resetAssessmentScoreAnalysisState } = getAssessmentScoreAnalysisSlice.actions;

export default getAssessmentScoreAnalysisSlice.reducer;