import React from 'react';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Grid,
  Typography,
} from '@mui/material';
import './Header.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useRoutes } from '../../constants/routes';
import { RootStoreType } from '../../redux/store.type';
import { useSelector } from 'react-redux';
import { useBranding } from '../../modules/Branding/reducers/getBranding';

export default function Header() {
  const location = useLocation();
  const { id } = useParams();
  const urlStrings = location.pathname.split('/');
  const Routes = useRoutes();
  const branding = useBranding();

  let parentUrl = '';
  let menuName = '';
  let subMenuName = '';

  function getNameByUrl(): void {
    for (const item of Routes) {
      if (urlStrings.includes(item.url)) {
        menuName = item.name;
      }

      if (item.submenus) {
        for (const submenu of item.submenus) {
          if (urlStrings.includes(submenu.url)) {
            menuName = item.name;
            subMenuName = submenu.name;
            parentUrl = item.url;
          }
        }
      }
    }
  }

  const loggedInUser = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.authReducers.user.currentUser;
  });

  getNameByUrl();

  return (
    <Grid container className="header-container"
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #EDEDED',
        padding: '15px 30px',
        width: '100%',
      }}
    >
      <Grid md={6} item className="side-bar" fontFamily={"Inter"} display={"flex"} alignItems={"center"}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="#2E3645" fontWeight="600" fontSize={"20px"}>
            <Link
              style={{
                textDecoration: 'none',
                color: '#2E3645',
              }}
              to={'/' + parentUrl}
            >
              {menuName}
            </Link>
          </Typography>
          {subMenuName && (
            <Typography color="#2E3645" fontWeight="600" fontSize={"20px"}>
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#2E3645',
                }}
                to={urlStrings[1] + (id ? '/' + id : '')}
              >
                {subMenuName}
              </Link>
            </Typography>
          )}
        </Breadcrumbs>
      </Grid>




      <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Avatar
          src={loggedInUser?.profile_image || undefined}
          sx={{
            backgroundColor: loggedInUser?.profile_image ? 'transparent' : branding?.secondary_color,
            color: 'white',
            width: 43,
            height: 43,
            fontSize: 18,
          }}
        >
          {loggedInUser?.fullname?.charAt(0)}
        </Avatar>
        <Box style={{ marginLeft: '10px' }}>

          <Typography fontFamily='Inter' fontWeight='600' color='#2E2E2E' fontSize={"16px"}>
            {loggedInUser?.fullname}
          </Typography>
          <Typography fontFamily='Inter' fontWeight='500' color='#6A6A6A' fontSize={"14px"}>
            {loggedInUser?.email}
          </Typography>
        </Box>
      </Grid>


    </Grid>
  );
}
