import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { AccountAssessmentListDetail } from '../type';
import { EndPoints } from '../../../constants/apiUrls';

export interface AccountAssessmentsReducer {
  status: RequestStatus;
  assessments: AccountAssessmentListDetail[] | null;
}
const initialState: AccountAssessmentsReducer = {
  status: RequestStatus.Init,
  assessments: null,
};

export const getAccountAssessments = createAsyncThunk<AccountAssessmentListDetail[]>(
  '/getAccountAssessments',
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse<AccountAssessmentListDetail[]> = await axiosV2Instance.get(
        EndPoints.ACCOUNT_ASSESSMENT
      );
      return response.data; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const getAccountAssessmentsSlice = createSlice({
  name: '/getAccountAssessments',
  initialState,
  reducers: {
      resetAccountAssessmentListState: (state) => {
        Object.assign(state, initialState);
      },},
  extraReducers: (builder) => {
    builder.addCase(getAccountAssessments.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getAccountAssessments.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.assessments = action.payload;
    });
    builder.addCase(getAccountAssessments.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const { resetAccountAssessmentListState } = getAccountAssessmentsSlice.actions;

export default getAccountAssessmentsSlice.reducer;