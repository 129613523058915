import React from 'react';
import { Box, IconButton, Typography, Grid, Tooltip } from '@mui/material';
import { LightAssessmentOverviewDetails, AccountInfo } from '../../../../Assessments/type';
import { AccountSettingsUser } from '../../../../Accounts/AccountSettings/AccountSettings';
import { FieldName, FieldValue, GridItemFieldName, GridItemFieldValue } from '../EvaluationDetails.style';
import BarcodeIcon from '../../../../../assets/svg-components/BarcodeIcon';
import InfoIcon from '../../../../../assets/svg-components/InfoIcon';
import IdentificationBadgeIcon from '../../../../../assets/svg-components/IdentificationBadgeIcon';
import AtIcon from '../../../../../assets/svg-components/AtIcon';
import HouseSimpleIcon from '../../../../../assets/svg-components/HouseSimpleIcon';
import MapPinIcon from '../../../../../assets/svg-components/MapPinIcon';
import PhoneIcon from '../../../../../assets/svg-components/PhoneIcon';
import { AccountContact } from '../../../../Accounts/types';

const formatPhoneNumber = (phone: string): string => {
  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, "");

  // Apply formatting based on length
  if (digits.length === 10) {
    // Format as (123) 456-7890
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else if (digits.length === 11 && digits.startsWith("1")) {
    // Format as +1 (123) 456-7890
    return `+${digits[0]} (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
  } else if (digits.length === 7) {
    // Format as 123-4567
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  }

  // If the phone number doesn't match expected lengths, return as-is
  return phone;
};

const GeneralAssessmentInfoCard = ({
  lightInfo,
  accountInfo,
  accountUserData,
  content,
  accountExecutiveNameTranslation
}: {
  lightInfo: LightAssessmentOverviewDetails;
  accountInfo: AccountInfo;
  accountUserData: AccountSettingsUser[];
  content: string;
  accountExecutiveNameTranslation: string;
}) => {
  const primaryContact = accountInfo?.account_contacts?.find(
    (contact: AccountContact) => contact.is_primary_contact,
  );

  return (
    <Box
      sx={{
        marginLeft: '10px',
        marginBottom: '1em',
        backgroundColor: '#FFF',
        padding: '30px 20px',
        borderRadius: '16px',
        border: '1px solid #E7E9F9',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
        }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        General Info
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <BarcodeIcon width={20} height={20} />
            </IconButton>
            <FieldName> ID </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>
              {lightInfo?.uuid || 'N/A'} {/* Updated field */}
            </FieldValue>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <InfoIcon width={20} height={20} />
            </IconButton>
            <FieldName> Target Responses </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>
              {lightInfo?.target_responses || 'N/A'} {/* Updated field */}
            </FieldValue>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <IdentificationBadgeIcon width={20} height={20} />
            </IconButton>
            <FieldName> Contact </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>
              {primaryContact && primaryContact.first_name && primaryContact.last_name
                ? `${primaryContact.first_name} ${primaryContact.last_name}`
                : primaryContact?.name || 'N/A'}
            </FieldValue>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <HouseSimpleIcon width={20} height={20} />
            </IconButton>
            <FieldName> Company </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>{accountInfo?.name}</FieldValue>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <MapPinIcon width={20} height={20} />
            </IconButton>
            <FieldName> Location </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <Tooltip title={accountInfo?.address1 || 'N/A'} placement="top-start">
              <FieldValue
                sx={{
                  maxWidth: '200px',
                }}
              >
                {accountInfo?.address1 || 'N/A'}
              </FieldValue>
            </Tooltip>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <PhoneIcon width={20} height={20} />
            </IconButton>
            <FieldName> Phone </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>
              {primaryContact?.phone ? formatPhoneNumber(primaryContact.phone) : 'N/A'}
            </FieldValue>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <AtIcon width={20} height={20} />
            </IconButton>
            <FieldName> Email </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>{accountInfo?.email || 'N/A'}</FieldValue>
          </GridItemFieldValue>

          <GridItemFieldName item xs={6}>
            <IconButton disabled>
              <IdentificationBadgeIcon width={20} height={20} />
            </IconButton>
            <FieldName> {accountExecutiveNameTranslation} </FieldName>
          </GridItemFieldName>
          <GridItemFieldValue item xs={6} sx={{ display: 'flex', justifyContent: content }}>
            <FieldValue>
              {accountUserData?.find(
                (contact: AccountSettingsUser) =>
                  contact.role === 'account_executive',
              )?.fullname || 'N/A'}
            </FieldValue>
          </GridItemFieldValue>
        </Grid>
      </Box>
    </Box>
  );
};

export default GeneralAssessmentInfoCard;
